<template>
  <div class="article">
    <el-card class="box-card">
      <div class="title">添加文章</div>
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-width="80px">
        <el-form-item label="文章标题:" label-width="120" prop="title">
          <el-input v-model.trim="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="文章内容:" label-width="120" prop="content">
          <el-input type="textarea" v-model.trim="ruleForm.content"></el-input>
        </el-form-item>
        <el-form-item label="文章类型:" label-width="120" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择文章类型">
            <el-option
              v-for="item in articleType"
              :key="item.type"
              :label="item.name"
              :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片:">
          <el-upload
            action="https://onlinemall.bbjt.cc/cms/file"
            :headers="config"
            list-type="picture-card"
            :before-upload="beforeUpload"
            :limit="5"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :on-error="handleError"
            :on-success="fileUploadSuccess">
            <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
        </el-form-item>
        <el-form-item class="bnt">
          <el-button type="danger" @click="onSubmit">立即发表</el-button>
        </el-form-item>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "BbjtwebsiteArticle",
  data() {
    return {
      userInfo: {},
      ruleForm: {
        type: null,
        title: "",
        content: "",
        imgs: []
      },
      articleType: [
        {name:"技术分享",type: 1},
        {name:"吐槽反馈",type: 2},
        {name:"趣事分享",type: 3}
      ],
      rules: {
        type: [
          { required: true, message: '请选择文章类型', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请输入文章标题', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入文章内容', trigger: 'blur' },
          { min: 3, max: 1000, message: '长度在 3 到 1000 个字符', trigger: 'blur' }
        ],
      },
      dialogImageUrl: '',
      dialogVisible: false,
    };
  },

  async mounted() {
    let userMap = JSON.parse(localStorage.getItem("userMap"))
      if(userMap) {
        this.userInfo = userMap
      }
      // let res = await this.api.post(this.apiPath.hotTopic,{});
  },
  computed: {
    config() {
      this.token = localStorage.getItem("token")
      return { Authorization: 'Bearer '+this.token };
    },
  },

  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    fileUploadSuccess(resource) {
      this.ruleForm.imgs.push(resource[0].url)
    },
    handleError(err) {
      let erro = JSON.parse(err.message)
      this.$message.error(erro.message);
    },
    beforeUpload(file) {
      let _this = this
        const is1M = file.size / 5120 / 5120 < 5; // 限制小于1M
        if (!is1M) {
          _this.$message.error('大小不可超过5MB')
        }
        return is1M
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制最高上传 5 张图片，本次上传了 ${files.length} 张图片，共上传了 ${files.length + fileList.length} 张图片`);
    },
    onSubmit() {
      this.getPublished()
    },
    async getPublished() {
      let data = {
        type: this.ruleForm.type,
        host_img: this.userInfo.headImg,
        user_id: this.userInfo.id,
        username: this.userInfo.username,
        title: this.ruleForm.title,
        content: this.ruleForm.content,
        imgs: this.ruleForm.imgs,
        service_type: localStorage.getItem("serviceType")*1
      }
      let res = await this.api.post(this.apiPath.published,data);
      if(res.code == 0) {
        this.ruleForm = {}
        this.$message({
          message: '文章发表成功，请等待审核！',
          type: 'success'
        });
        this.$router.push({
          path: '/my',
        })
      } else {
        this.$message({
          message: res.msg,
          type: 'warning'
        });
      }
    }
  },
};
</script>

<style lang="less" scoped>
.article {
  background: #f2f2f2;
  padding: 50px 0;
}
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 1150px;
  margin: auto;
  .title {
    width: 100px;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #7f0114;
    border-bottom: 1px solid #7f0114;
  }
}
.el-form {
  width: 60%;
}
@media screen and (max-width: 750px) {
  .box-card {
    width: 100%;
  }
}
.el-form {
  width: 90%;
}
</style>
<style>
.el-form-item__content {
  display: flex !important;
}
</style>
